import React, { useState, useEffect } from 'react';
import './CookiePopup.css'; // Import the CSS file
import { BiCookie } from 'react-icons/bi'; // Optional: Icon for visual appeal

const CookiePopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted cookies
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      // Show the popup if consent not given
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Store the user's consent in localStorage
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  const handleDecline = () => {
    // Optional: Handle decline action
    // For compliance, you might restrict certain functionalities
    setIsVisible(false);
  };

  if (!isVisible) return null; // Do not render the popup if not visible

  return (
    <div className="cookie-popup-overlay">
      <div className="cookie-popup-card">
        <div className="cookie-popup-icon">
          <BiCookie size={40} color="#0071E3" />
        </div>
        <div className="cookie-popup-content">
          <h3>We Value Your Privacy</h3>
          <p>
            We use cookies to enhance your experience on our website. By continuing to visit this site you agree to our use of cookies.
          </p>
          <div className="cookie-popup-buttons">
            <button className="cookie-button accept" onClick={handleAccept}>
              Accept
            </button>
            <button className="cookie-button decline" onClick={handleDecline}>
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePopup;
