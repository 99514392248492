import React, { useState,useEffect } from 'react';
import './ContactUs.css';
import Cookies from 'js-cookie';


import { FaCheckCircle } from 'react-icons/fa'
const countries = [
  "United States", "Canada", "United Kingdom", "Australia", "India", "Germany", "France", "China", 
  "Japan", "South Korea", "Brazil", "South Africa", "Mexico", "Italy", "Russia", "Spain", "Netherlands",
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", 
  "Armenia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", 
  "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", 
  "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon", 
  "Central African Republic", "Chad", "Chile", "Colombia", "Comoros", "Congo (Congo-Brazzaville)", 
  "Costa Rica", "Côte d'Ivoire", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Democratic Republic of the Congo",
  "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", 
  "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "Gabon", 
  "Gambia", "Georgia", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", 
  "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "Indonesia", "Iran", "Iraq", "Ireland", 
  "Israel", "Jamaica", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", 
  "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", 
  "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", 
  "Mauritania", "Mauritius", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", 
  "Mozambique", "Myanmar (Burma)", "Namibia", "Nauru", "Nepal", "New Zealand", "Nicaragua", "Niger", 
  "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", 
  "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Rwanda", "Saint Kitts and Nevis", 
  "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", 
  "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", 
  "Slovenia", "Solomon Islands", "Somalia", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", 
  "Syria", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", 
  "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", 
  "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
];

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    jobTitle: '',
    email: '',
    phone: '',
    country: '',
    inquiryType: '',
    message: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // Tracks form submission
  const [submissionError, setSubmissionError] = useState(''); // Tracks submission errors

  // Check if the form has been submitted by reading the cookie
  useEffect(() => {
    const formSubmitted = Cookies.get('formSubmitted');
    if (formSubmitted === 'true') {
      setIsSubmitted(true);
    }
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include', // Include cookies in the request
      });

      if (response.ok) {
        setIsSubmitted(true); // Update state to show thank you message
        Cookies.set('formSubmitted', 'true', { expires: 1 }); // Set cookie for 1 day
      } else {
        const data = await response.json();
        console.error('Error sending email:', data.message);
        setSubmissionError(data.message || 'Error sending email. Please try again.');
        alert('Error sending email. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmissionError('An error occurred while sending your email. Please try again.');
      alert('An error occurred while sending your email. Please try again.');
    }
  };

  // Optional: Clear the submission cookie after some time or on specific actions
  const clearSubmission = () => {
    Cookies.remove('formSubmitted');
    setIsSubmitted(false);
  };

  return (
    <div className="contact-us-page">
      <div className="contact-container">
        {/* Left Section: Contact Info */}
        <div className="contact-info">
          <h1 className="contact-title">GETTING IN TOUCH</h1>
          <p className="contact-description">
            Do you have a question or need more info? Please enter your information and describe your inquiry, and we’ll get back to you as soon as possible. Thanks!
          </p>
          <p className="contact-description">
            Looking for product support? For the fastest response, please contact our support team via email at <a href="mailto:support@erasys.co.uk">support@erasys.co.uk</a>
          </p>
        </div>

        {/* Right Section: Contact Form or Success Message */}
        <div className="contact-form-container">
          {!isSubmitted ? (
            <form className="contact-form" onSubmit={handleSubmit}>
              {/* Display submission error if any */}
              {submissionError && (
                <div className="error-message">
                  <p>{submissionError}</p>
                </div>
              )}

              <div className="form-group">
                <label htmlFor="firstName">First Name *</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  placeholder="Enter your first name"
                />
              </div>

              <div className="form-group">
                <label htmlFor="lastName">Last Name *</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  placeholder="Enter your last name"
                />
              </div>

              <div className="form-group">
                <label htmlFor="companyName">Company Name *</label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  required
                  placeholder="Enter your company name"
                />
              </div>

              <div className="form-group">
                <label htmlFor="jobTitle">Job Title *</label>
                <input
                  type="text"
                  id="jobTitle"
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleChange}
                  required
                  placeholder="Enter your job title"
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email Address *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Enter your email address"
                />
              </div>

              <div className="form-group">
                <label htmlFor="phone">Phone Number *</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  placeholder="Enter your phone number"
                />
              </div>

              <div className="form-group">
                <label htmlFor="country">Country *</label>
                <select
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="inquiryType">Inquiry Type *</label>
                <select
                  id="inquiryType"
                  name="inquiryType"
                  value={formData.inquiryType}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Inquiry Type</option>
                  <option value="Sales">Sales</option>
                  <option value="Product Information">Product Information</option>
                  <option value="Security Information">Security Information</option>
                  <option value="Investor Relations">Investor Relations</option>
                  <option value="Media">Media</option>
                  <option value="Partner Opportunities">Partner Opportunities</option>
                  <option value="Report Abuse">Report Abuse</option>
                  <option value="Feedback">Feedback</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="5"
                  placeholder="Enter your message here"
                ></textarea>
              </div>

              <button type="submit" className="submit-button-1">Submit</button>
            </form>
          ) : (
            <div className="thank-you-message">
              <FaCheckCircle size={50} color="green" />
              <h2>Thank You!</h2>
              <p>Your inquiry has been successfully submitted. We will get back to you soon.</p>
              {/* Optional: Add a button to allow users to submit another inquiry */}
              <button onClick={clearSubmission} className="submit-button-1">Submit Another Inquiry</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;