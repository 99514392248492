import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Digital from './digital.png';
import RealTime from './real-time.png';
import Interactive from './robotic-hand.png';
import Dashboard from './dashboard (1).png';
import Guidelines from './guidelines.png';
import longterm from './long-term-planning.png';

// Styled Components
const SectionWrapper = styled.div`
  background: ${(props) => props.background};
  background-size: cover;
  transition: background 1s ease;
  min-height: 100vh; /* Ensure at least full viewport height */
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden; /* Prevent internal scrolling */
  color: #fff;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-align: center;
`;

const DiagramWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 400px;
`;

const DiagramItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #333;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 250px;
  position: absolute;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
`;

const DiagramText = styled.p`
  margin-top: 10px;
  font-size: 0.9rem;
  color: white;
`;

const CurveLineWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  margin-top: 40px;
`;

const AnimatedSVG = styled(motion.svg)`
  position: absolute;
  width: 100%;
  height: 200px;
  fill: none;
  stroke: #61dafb;
  stroke-width: 2;
`;

const BottomText = styled.p`
  font-size: 1.1rem;
  margin-top: 40px;
  max-width: 600px;
  text-align: center;
  color: white;
`;

const Section = React.forwardRef((props, ref) => {
  const features = [
    {
      title: 'Collect Digital Biometrics',
      description: 'Capture digital biometrics while users interact with computers.',
      img: Digital,
      background: 'linear-gradient(135deg,rgb(120, 224, 243),rgb(16, 73, 134))',
    },
    {
      title: 'Real-Time Feedback',
      description: 'Provide real-time feedback to users for their tasks and health.',
      img: RealTime,
      background: 'linear-gradient(135deg, #141e30, #243b55)',
    },
    {
      title: 'Interactive Guidance',
      description:
        'Improve productivity by providing interactive guidance and identifying productivity patterns.',
      img: Interactive,
      background: 'linear-gradient(135deg, #3a1c71, rgb(109, 157, 215), rgb(44, 137, 203))',
    },
    {
      title: 'User Dashboard',
      description: 'Dashboard for users to monitor their performance and wellness.',
      img: Dashboard,
      background: 'linear-gradient(135deg, #373b44, #4286f4)',
    },
    {
      title: 'Health Guidelines',
      description:
        'HR compliance and occupational health guidelines for better workplace health.',
      img: Guidelines,
      background: 'linear-gradient(135deg, rgb(70, 177, 179), rgb(63, 130, 237))',
    },
    {
      title: 'Long-Term Benefits',
      description:
        'Use over a period of time to help users live healthier and happier lives.',
      img: longterm,
      background: 'linear-gradient(135deg, #0f2027, #203a43, #2c5364)',
    },
  ];

  const [currentFeature, setCurrentFeature] = useState(0);
  const pathRef = useRef(null);
  const [points, setPoints] = useState([]);
  const isThrottled = useRef(false); // Ref to manage throttling
  const sectionRef = useRef(null); // Ref for the SectionWrapper DOM element
  const [isMobile, setIsMobile] = useState(false); // State to detect mobile view

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (pathRef.current) {
      const pathLength = pathRef.current.getTotalLength();
      const newPoints = features.map((_, index) => {
        const scaleFactor = isMobile ? 0.9 : 1;
        let lengthAtPoint = (index / (features.length - 1)) * pathLength * scaleFactor;

        if (isMobile && index === 0) {
          lengthAtPoint += 20;
        }

        const { x, y } = pathRef.current.getPointAtLength(lengthAtPoint);
        return { x, y };
      });
      setPoints(newPoints);
    }
  }, [pathRef, features.length, isMobile]);

  useEffect(() => {
    const sectionElement = sectionRef.current;
    if (!sectionElement) return;

    const handleWheel = (e) => {
      if (isThrottled.current) return;

      if (e.deltaY > 0) {
        // Scrolling Down
        if (currentFeature < features.length - 1) {
          e.preventDefault();
          setCurrentFeature((prev) => prev + 1);
        }
      } else if (e.deltaY < 0) {
        // Scrolling Up
        if (currentFeature > 0) {
          e.preventDefault();
          setCurrentFeature((prev) => prev - 1);
        }
      }

      isThrottled.current = true;
      setTimeout(() => {
        isThrottled.current = false;
      }, 800);
    };

    // Add wheel event listener only when the section is in view
    // Use IntersectionObserver to detect when the section is in view
    const observerOptions = {
      root: null, // viewport
      threshold: 0.5, // 50% in view
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          sectionElement.addEventListener('wheel', handleWheel, { passive: false });
        } else {
          sectionElement.removeEventListener('wheel', handleWheel, { passive: false });
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);
    observer.observe(sectionElement);

    return () => {
      sectionElement.removeEventListener('wheel', handleWheel, { passive: false });
      observer.unobserve(sectionElement);
    };
  }, [currentFeature, features.length]);

  return (
    <SectionWrapper
      ref={(node) => {
        sectionRef.current = node;
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      }}
      background={features[currentFeature].background}
    >
      <Title>WhiteMatter Features</Title>

      <DiagramWrapper>
        <AnimatePresence mode="wait">
          <DiagramItem
            key={features[currentFeature].title}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 1.2, ease: 'easeInOut' }}
          >
            <img
              src={features[currentFeature].img}
              alt={features[currentFeature].title}
              style={{ width: '100px', marginBottom: '20px' }}
            />
            <h3>{features[currentFeature].title}</h3>
            <DiagramText>{features[currentFeature].description}</DiagramText>
          </DiagramItem>
        </AnimatePresence>
      </DiagramWrapper>

      <CurveLineWrapper>
        <AnimatedSVG viewBox="0 0 1000 200">
          <motion.path
            d="M0,150 C250,50 750,50 1000,150"
            ref={pathRef}
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.2, ease: 'easeInOut' }}
            fill="none"
            stroke="#61dafb"
            strokeWidth="2"
          />

          {points.map((point, index) => {
            const isActive = currentFeature === index;
            return (
              <motion.circle
                key={index}
                cx={point.x}
                cy={point.y}
                r={isActive ? (isMobile ? 16 : 10) : isMobile ? 12 : 6}
                fill={isActive ? '#f39c12' : '#999'}
                transition={{ duration: 0.3, type: 'spring', stiffness: 150 }}
                style={{ cursor: 'pointer' }}
                onClick={() => setCurrentFeature(index)}
                whileHover={{ scale: 1.3 }}
              />
            );
          })}
        </AnimatedSVG>
      </CurveLineWrapper>

      <BottomText>
        Use these features over time to improve health and productivity!
      </BottomText>

      {/* Optionally, mark each feature section for scrolling */}
      {features.map((feature, index) => (
        <div key={index} id={`feature-${index}`} style={{ height: '0px' }}></div>
      ))}
    </SectionWrapper>
  );
});

export default Section;
