import React from 'react';
import './LogoSection.css'; // Create a CSS file for styles

import NvidiaLogo from './nvidia.png'; // Replace with the actual path to your logo assets



const LogoSection = () => {
  return (
    <div className="logo-section">
      <h2 className="logo-section-title">Supported by global leading innovation organizations</h2>
      <div className="logo-container">
        <img src={NvidiaLogo} alt="NVIDIA Inception Program" className="logo" />
    
        
        
      </div>
    </div>
  );
};

export default LogoSection;
