import React, { useState } from 'react';
import './DownloadPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';

const DownloadPage = () => {
  // State to toggle Mac dropdown
  const [showMacDropdown, setShowMacDropdown] = useState(false);

  const handleDownload = (platform, arch) => {
    // Decide the file URL based on platform and architecture
    let fileUrl = '';

    if (platform === 'macos') {
      // Example links: adjust to your actual ARM64/Intel MacOS installers
      if (arch === 'arm64') {
        fileUrl =
          'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter-MacOs-Arm64.zip';
      } else {
        fileUrl =
          'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter_MacOs-Intel.zip';
      }
    } else if (platform === 'windows') {
      fileUrl =
        'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter.zip';
    }

    // Trigger browser download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleMacDropdown = () => {
    setShowMacDropdown((prevState) => !prevState);
  };

  return (
    <div className="download-page">
      <main className="main-content-download">
        <h1 className="title-download">
          Optimize Productivity and Maintain Mental Well-being with WhiteMatter
        </h1>
        <p className="subtitle-download">
          Discover WhiteMatter, an innovative AI-powered neurofeedback solution
          designed to track stress, cognitive fatigue, and productivity — all
          without the need for wearables. Download now to access advanced tools
          that enhance mental wellness, boost performance, and provide
          actionable insights to optimize your team's success.
        </p>
        <div className="platform-buttons-download">
          {/* ---------- MacOS Button + Dropdown ---------- */}
          <div className="platform-button-container-download mac-dropdown-container">
            <button
              className="platform-button-download macos-button-download"
              onClick={toggleMacDropdown}
            >
              <FontAwesomeIcon icon={faApple} size="2x" />
            </button>
            <p className="platform-text-download">MacOS</p>

            {/* Dropdown for Mac (ARM64 / Intel) */}
            {showMacDropdown && (
              <div className="mac-dropdown-menu">
                <button
                  className="mac-dropdown-item"
                  onClick={() => handleDownload('macos', 'arm64')}
                >
                  ARM64 (M1/M2)
                </button>
                <button
                  className="mac-dropdown-item"
                  onClick={() => handleDownload('macos', 'intel')}
                >
                  Intel
                </button>
              </div>
            )}
          </div>

          {/* ---------- Windows Button ---------- */}
          <div className="platform-button-container-download">
            <button
              className="platform-button-download windows-button-download"
              onClick={() => handleDownload('windows')}
            >
              <FontAwesomeIcon icon={faWindows} size="2x" />
            </button>
            <p className="platform-text-download">Windows</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DownloadPage;
