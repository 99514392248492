import React, { useState } from 'react';
import './OtpVerification.css';
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

const OtpVerification = () => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('/api/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // <-- important for sending/receiving cookies
        body: JSON.stringify({ otp }),
      });

      const data = await response.json();

      if (response.status === 400) {
        setError(data.message);
      } else if (response.status === 200) {
        // No longer storing the token in localStorage; the server sets the cookie
        setSuccessMessage('Successfully verified! You are now registered.');

        // Redirect user after a short delay
        setTimeout(() => {
          navigate('/');
        }, 2000);
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBackClick = () => {
    // Example: Remove any session or local storage items used during signup
    sessionStorage.removeItem('otpData');
    localStorage.removeItem('registrationData');
    navigate('/signup');
  };

  return (
    <div className="container2">
      <div className="card2">
        <button className="back-button" onClick={handleBackClick}>
          <BiArrowBack size={24} />
        </button>
        <h1>Verification Code</h1>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter Verification Code"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Verifying...' : 'Verify'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default OtpVerification;
