import React, { useState, useEffect, useRef } from 'react';
import './Products.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faRobot, faWind, faChartLine, faKey } from '@fortawesome/free-solid-svg-icons';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';

// Import your new advanced 3D section with forwarded ref
import Section from './Section'; // Adjust path as needed

import Dashboard from './longevity1.png';
import AIImage from './ain.png';
import BoxBreathingImage from './smaller_transformed_image_resized (1).png';
import ProductivityImage from './apps_resized.png';

const Products = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // State management for selected app and its content
  const [selectedApp, setSelectedApp] = useState('Dashboard');
  const [image, setImage] = useState(Dashboard);
  const [text, setText] = useState('Optimize your productivity with AI-powered apps integrated into Whitematter.');

  // Reference to the <Section /> component
  const sectionRef = useRef(null);

  // Function to scroll to the <Section /> component
  const scrollToSection = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Navigation functions
  const goToDownload = () => {
    navigate('/download');
  };
  const goToPricing = () => {
    navigate('/pricing'); // Corrected navigation to /pricing instead of /download
  };

  return (
    <div className="products-page">
      {/* ================== Hero Section ================== */}
     
<section className="hero-section tech-bg">
        <div className="hero-content">
          <h1 className="hero-title">Whitematter</h1>
          <p className="hero-subtitle">
            <span className="gradient-text2">AI-powered</span> brain tracking for enhanced focus and productivity.
          </p>
          <div className="cta-buttons">
            {/* Updated Learn More button with onClick handler */}
            <button className="cta-button learn-more" onClick={scrollToSection}>
              Learn More
            </button>
            <button className="cta-button buy-now" onClick={goToPricing}>
              Try For Free
            </button>
          </div>
        </div>
      </section>

      {/* ================== Key Feature Section (UNCHANGED) ================== */}
      <section className="key-feature-section">
        <div className="key-feature-grid">
          <div className="key-feature-item">
            <h1>24/7</h1>
            <h3>Real-Time Brain Monitoring</h3>
            <p>Continuous tracking of cognitive performance to ensure optimal mental clarity throughout the day.</p>
          </div>
          <div className="key-feature-item">
            <h1>50%</h1>
            <h3>Increased Productivity</h3>
            <p>Our effective feedback and productivity monitoring systems are designed to streamline your tasks and invigorate efficiency.</p>
          </div>
          <div className="key-feature-item">
            <h1>Adaptive</h1>
            <h3>Personalized Cognitive Feedback</h3>
            <p>AI learns from your habits and provides personalized tips to improve focus, reduce stress, and boost productivity.</p>
          </div>
          <div className="key-feature-item">
            <h1>3000</h1>
            <h3>Data Points Processed</h3>
            <p>Leverage advanced AI processes over 3000 data points per hour, offering detailed reports on your mental well-being and productivity.</p>
          </div>
        </div>
      </section>

      {/* ============ New 3D Section with ref ============ */}
      <Section ref={sectionRef} />

      {/* ================== Parallax / Platform Section ================== */}
      <section className="parallax-section">
        <div className="parallax-content">
          <h2>Enhance Your Performance</h2>
          <p>Discover how Whitematter can help you achieve new levels of productivity and mental well-being.</p>
          <div className="platform-buttons1">
            <div className="platform-button-container1">
              <button className="platform-button1 macos-button" onClick={goToDownload}>
                <FontAwesomeIcon icon={faApple} size="2x" />
              </button>
              <p className="platform-text1">MacOS</p>
            </div>
            <div className="platform-button-container1">
              <button className="platform-button1 windows-button" onClick={goToDownload}>
                <FontAwesomeIcon icon={faWindows} size="2x" />
              </button>
              <p className="platform-text1">Windows</p>
            </div>
            <div className="platform-button-container1">
              <button className="platform-button1 trial-button" onClick={goToPricing}>
                <FontAwesomeIcon icon={faKey} size="2x" />
              </button>
              <p className="platform-text1">Free Trial</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Products;