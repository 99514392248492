import React, { useRef, useState } from 'react';
import './VideoSection.css';

const VideoSection = () => {
  const videoRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);

  // Toggle play/pause
  const handlePauseClick = () => {
    if (!videoRef.current) return;
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPaused(false);
    } else {
      videoRef.current.pause();
      setIsPaused(true);
    }
  };

  // Toggle mute/unmute
  const handleSoundClick = () => {
    if (!videoRef.current) return;
    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(!isMuted);
  };

  // Toggle full-screen
  const handleFullScreenClick = () => {
    if (!videoRef.current) return;

    // If we're not in full screen, request it
    if (!isFullScreen) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        // Safari
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        // Firefox
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.msRequestFullscreen) {
        // IE/Edge
        videoRef.current.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      // If we are in full screen, exit
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  return (
    <section className="video-section-apple-like">
      <div className="video-card">
        {/* The video */}
        <video
          ref={videoRef}
          src="https://whitematter-video-htdmb7bfgjh4gkb6.z01.azurefd.net/whitemattervideo/whitematter.mp4"
          playsInline
          autoPlay
          loop
          muted={isMuted}
          controls={false}  // Hide browser default controls
          className="apple-like-video"
        />

        {/* Custom minimal controls */}
        <div className="video-controls">
          {/* Play/Pause */}
          <button className="control-button" onClick={handlePauseClick}>
            {isPaused ? '▶' : '❚❚'}
          </button>

          {/* Sound (mute/unmute) */}
          <button className="control-button" onClick={handleSoundClick}>
            {isMuted ? '🔇' : '🔊'}
          </button>

          {/* Full-screen */}
          <button className="control-button" onClick={handleFullScreenClick}>
            {isFullScreen ? '⤢' : '⤢'} 
            {/* You can use a different symbol for "enter" vs "exit" fullscreen */}
          </button>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
