import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Signup.css';
import WhiteMatter from './White Matter - 440x280 px - original dark grey on transparent.png';
import { BiArrowBack } from 'react-icons/bi';
import zxcvbn from 'zxcvbn'; // Import zxcvbn

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    workEmail: '',
    jobTitle: '',
    departmentId: '',
    type: '',
    password: '',
    confirmPassword: '',
    termsAccepted: false,
    registrationCode: '',
  });
  const [departments, setDepartments] = useState([]);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordFeedback, setPasswordFeedback] = useState('');
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    symbol: false,
  });

  const navigate = useNavigate();

  // Fetch department names from the server when the component mounts
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await fetch('/api/departments');
        const data = await response.json();
        setDepartments(data);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    fetchDepartments();
  }, []);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));

    // If the changed field is the password, evaluate its strength and validations
    if (name === 'password') {
      const evaluation = zxcvbn(value);
      const meetsValidations = value.length >= 8 &&
        /[A-Z]/.test(value) &&
        /[!@#$%^&*(),.?":{}|<>]/.test(value);

      setPasswordValidations({
        length: value.length >= 8,
        uppercase: /[A-Z]/.test(value),
        symbol: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      });

      // If password meets all validations, use zxcvbn's score; otherwise, set to 0
      setPasswordScore(meetsValidations ? evaluation.score : 0);
      setPasswordFeedback(meetsValidations ? (evaluation.feedback.warning || '') : 'Password does not meet the required criteria.');
    }
  };

  const handleDepartmentChange = (e) => {
    const selectedDepartment = departments.find(
      (dept) => dept.DepartmentName === e.target.value
    );
    setFormData((prevState) => ({
      ...prevState,
      departmentId: selectedDepartment?.DepartmentID || '',
    }));
  };

  const getPasswordStrengthLabel = (score) => {
    switch (score) {
      case 0:
        return 'Very Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return '';
    }
  };

  const getPasswordStrengthColor = (score) => {
    switch (score) {
      case 0:
        return '#ff4d4d'; // Red
      case 1:
        return '#ff944d'; // Orange
      case 2:
        return '#ffd11a'; // Yellow
      case 3:
        return '#99e600'; // Light Green
      case 4:
        return '#00e600'; // Green
      default:
        return '#e0e0e0'; // Grey
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    // Password validation: Ensure it includes symbols, uppercase letters, etc.
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      setError(
        'Password must be at least 8 characters long, include an uppercase letter, and a symbol.'
      );
      setIsSubmitting(false);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      setIsSubmitting(false);
      return;
    }

    if (!formData.termsAccepted) {
      alert('Please accept the terms.');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch('/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // <-- important if your server sets a session cookie
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.status === 400) {
        setError(data.message);
      } else if (response.status === 200) {
        // If OTP is sent, redirect to OTP verification page
        navigate('/otp', { state: { email: formData.workEmail } });
      }
    } catch (err) {
      console.error('Error signing up:', err);
      setError('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className="container-signup">
      <div className="card3">
        <div className="form-section3">
          <button className="back-button-signup" onClick={handleBackClick}>
            <BiArrowBack size={24} />
          </button>
          <h1 className="title">
            Join <span className="brand-name">Erasys</span>
          </h1>
          <p className="subtitle">Unlock your 60-day free trial. No credit card needed.</p>
          {error && <p className="error-message">{error}</p>}

          <form onSubmit={handleSubmit}>
            <div className="input-wrapper">
              <input
                className="signup"
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="First Name"
                required
              />
            </div>
            <div className="input-wrapper">
              <input
                className="signup"
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
                required
              />
            </div>
            <div className="input-wrapper">
              <input
                className="signup"
                type="email"
                name="workEmail"
                value={formData.workEmail}
                onChange={handleChange}
                placeholder="Work Email"
                required
              />
            </div>
            <div className="input-wrapper">
              <input
                className="signup"
                type="text"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                placeholder="Job Title"
                required
              />
            </div>
            <div className="input-wrapper">
              <select
                name="department"
                value={
                  departments.find((dept) => dept.DepartmentID === formData.departmentId)
                    ?.DepartmentName || ''
                }
                onChange={handleDepartmentChange}
                required
              >
                <option value="">Select Department</option>
                {departments.map((dept) => (
                  <option key={dept.DepartmentID} value={dept.DepartmentName}>
                    {dept.DepartmentName}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-wrapper">
              <select name="type" value={formData.type} onChange={handleChange} required>
                <option value="">Type</option>
                <option value="B2C">Personal</option>
                <option value="B2B">Business</option>
              </select>
            </div>
            {formData.type === 'B2B' && (
              <div className="input-wrapper">
                <input
                  className="signup"
                  type="text"
                  name="registrationCode"
                  value={formData.registrationCode}
                  onChange={handleChange}
                  placeholder="Registration Code"
                  required
                />
              </div>
            )}
            <div className="input-wrapper">
              <input
                className="signup"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
            </div>
            {/* Password Strength Meter */}
            {formData.password && (
              <div className="password-strength-meter">
                <div
                  className="password-strength-bar"
                  style={{
                    width: `${(passwordScore + 1) * 20}%`,
                    backgroundColor: getPasswordStrengthColor(passwordScore),
                  }}
                ></div>
                <span className="password-strength-label">
                  {getPasswordStrengthLabel(passwordScore)}
                </span>
              </div>
            )}
            {passwordFeedback && <p className="password-feedback">{passwordFeedback}</p>}

            {/* Password Requirements - Render only if password doesn't meet the validations */}
            {formData.password && (
              !passwordValidations.length ||
              !passwordValidations.uppercase ||
              !passwordValidations.symbol
            ) && (
              <div className="password-requirements">
                <p>Password must contain:</p>
                <ul>
                  <li className={passwordValidations.length ? 'valid' : 'invalid'}>
                    At least 8 characters
                  </li>
                  <li className={passwordValidations.uppercase ? 'valid' : 'invalid'}>
                    At least one uppercase letter
                  </li>
                  <li className={passwordValidations.symbol ? 'valid' : 'invalid'}>
                    At least one symbol (!@#$%^&*(),.?":{}|&lt;&gt;)
                  </li>
                </ul>
              </div>
            )}

            <div className="input-wrapper">
              <input
                className="signup"
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm Password"
                required
              />
            </div>
            <div className="checkbox-wrapper">
              <input
                className="checkbox"
                type="checkbox"
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleChange}
              />
              <label className="checkbox-label">
                I agree to the <a href="/privacy">Terms of Service</a>.
              </label>
            </div>
            <button className="button" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Get Started...' : 'Get Started'}
            </button>
          </form>
          <div className="link-wrapper">
            <p className="small-text">
              Already a member? <a href="/signin">Login</a>
            </p>
          </div>
        </div>

        <div className="image-section">
          <img className="image" src={WhiteMatter} alt="WhiteMatter" />
        </div>
      </div>
    </div>
  );
};

export default Signup;
