import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Sign.css';
import ExampleImage from './White Matter - 440x280 px - original dark grey on transparent.png';
import { BiArrowBack } from 'react-icons/bi';


const Sign = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    termsAccepted: false,
  });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  // For modals
  const [showResetModal, setShowResetModal] = useState(false);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const [resetEmail, setResetEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Handle login submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.termsAccepted) {
      alert('Please accept the terms.');
      return;
    }

    setIsSigningIn(true);
    setError('');
    setMessage('');

    try {
      // Important: Use credentials: 'include' to send/receive cookies
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        // The server sets the HttpOnly cookies, no token in body
        navigate('/account');
      } else {
        setError(data.message || 'Login failed. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again later.');
    } finally {
      setIsSigningIn(false);
    }
  };

  // Password reset flow
  const handlePasswordReset = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch('/api/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: resetEmail }),
      });

      if (response.ok) {
        setMessage('Reset code sent to your email.');
        setError('');
        setShowResetModal(false);
        setShowCodeModal(true);
      } else {
        setError('Failed to send reset code.');
      }
    } catch (err) {
      setError('Error sending reset password request.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVerifyCode = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch('/api/verify-reset-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: resetEmail, resetCode }),
      });

      if (response.ok) {
        setShowCodeModal(false);
        setShowPasswordModal(true);
        setError('');
        setMessage('');
      } else {
        setError('Invalid reset code.');
      }
    } catch (error) {
      setError('Error verifying reset code.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitNewPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await fetch('/api/reset-password-complete', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: resetEmail,
          newPassword,
        }),
      });

      if (response.ok) {
        setShowPasswordModal(false);
        setMessage('Password reset successful.');
        setError('');
      } else {
        setError('Failed to reset password.');
      }
    } catch (error) {
      setError('Failed to reset password.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className="container-signin">
      <div className="card-signin">
        <div className="form-section-signin">
          <button className="back-button-signin" onClick={handleBackClick}>
            <BiArrowBack size={24} />
          </button>
          <h1 className="title">
            Sign In to <span className="brand-name">Erasys</span>
          </h1>
          <p className="subtitle">Access your account now.</p>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="input-wrapper-signin">
              <input
                className="signin"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                required
              />
            </div>
            <div className="input-wrapper-signin">
              <input
                className="signin"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
            </div>
            <div className="checkbox-wrapper">
              <input
                className="checkbox"
                type="checkbox"
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleChange}
              />
              <label className="checkbox-label">
                I agree to the <a href="/privacy">Terms of Service</a>.
              </label>
            </div>
            <button className="button" type="submit" disabled={isSigningIn}>
              {isSigningIn ? 'Signing in...' : 'Sign in'}
            </button>
          </form>
          <div className="link-wrapper">
            <p className="small-text">
              Don’t have an account? <a href="/signup">Sign Up</a>
            </p>
            <p className="small-text">
              <a href="#!" onClick={() => setShowResetModal(true)}>
                Forgot Password?
              </a>
            </p>
          </div>
        </div>

        <div className="image-section">
          <img className="image" src={ExampleImage} alt="Sign In Illustration" />
        </div>
      </div>

      {/* Modal for sending reset password email */}
      {showResetModal && (
        <div className="modal-1">
          <div className="modal-content-1">
            <h2>Reset Your Password</h2>
            <p>Enter your email to receive a reset code:</p>
            {error && <div className="error-message">{error}</div>}
            {message && <div className="success-message">{message}</div>}
            <input
              className="signin-holder"
              type="email"
              placeholder="Enter your email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
            <button onClick={handlePasswordReset} className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
            <button className="close-button-signin" onClick={() => setShowResetModal(false)}>
              &times;
            </button>
          </div>
        </div>
      )}

      {/* Modal for entering reset code */}
      {showCodeModal && (
        <div className="modal-1">
          <div className="modal-content-1">
            <h2>Enter Reset Code</h2>
            <p>A code has been sent to your email. Enter it below:</p>
            {error && <div className="error-message">{error}</div>}
            <input
              className="signin-holder"
              type="text"
              placeholder="Enter reset code"
              value={resetCode}
              onChange={(e) => setResetCode(e.target.value)}
            />
            <button onClick={handleVerifyCode} className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Verifying...' : 'Verify'}
            </button>
            <button className="close-button-signin" onClick={() => setShowCodeModal(false)}>
              &times;
            </button>
          </div>
        </div>
      )}

      {/* Modal for entering new password */}
      {showPasswordModal && (
        <div className="modal-1">
          <div className="modal-content-1">
            <h2>Set New Password</h2>
            <p>Enter your new password below:</p>
            {error && <div className="error-message">{error}</div>}
            <input
              className="signin-holder"
              type="password"
              placeholder="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              className="signin-holder"
              type="password"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button onClick={handleSubmitNewPassword} className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Setting password...' : 'Set password'}
            </button>
            <button className="close-button-signin" onClick={() => setShowPasswordModal(false)}>
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sign;
