import React, { useState, useEffect } from 'react';
import './Header.css';
import Logo from './Erasys-logo.png';
import { FaBars, FaTimes, FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';

// Helper function to check if the user is authenticated
async function checkLoginStatus() {
  try {
    const response = await fetch('/api/profile', {
      method: 'GET',
      credentials: 'include',
    });
    return response.ok; // Returns true if response status is 200
  } catch (error) {
    console.error('Error checking login status:', error);
    return false;
  }
}

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu
  const [isProductsMenuOpen, setIsProductsMenuOpen] = useState(false); // State for desktop products submenu
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State for authentication status

  const navigate = useNavigate();
  const location = useLocation();

  // Check login status on component mount
  useEffect(() => {
    (async () => {
      const loggedIn = await checkLoginStatus();
      setIsLoggedIn(loggedIn);
    })();
  }, []);

  // Close the mobile menu when the route changes
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  // Toggle mobile menu open/close
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // Close the desktop products submenu when toggling the mobile menu
    if (isMenuOpen) {
      setIsProductsMenuOpen(false);
    }
  };

  // Toggle products submenu for desktop
  const toggleProductsMenu = (e) => {
    e.stopPropagation(); // Prevent event propagation to other elements
    setIsProductsMenuOpen((prev) => !prev);
  };

  // Close products submenu for desktop
  const closeProductsMenu = () => {
    setIsProductsMenuOpen(false);
  };

  // Handle user logout
  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout', {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        setIsLoggedIn(false);
        setIsMenuOpen(false);
        navigate('/'); // Redirect to home after logout
      } else {
        console.error('Logout failed.');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <header className="header">
      {/* Hamburger Icon (Visible on Mobile) */}
      <div
        className="hamburger-icon"
        onClick={toggleMenu}
        aria-label="Toggle menu"
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleMenu();
          }
        }}
      >
        {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
      </div>

      {/* Logo */}
      <div className="logo1">
        <Link to="/">
          <img src={Logo} alt="Erasys Logo" />
        </Link>
      </div>

      {/* Navigation Menu */}
      <nav className={`nav ${isMenuOpen ? 'nav-open' : ''}`}>
        <ul>
          <li className={location.pathname === '/' ? 'active' : ''}>
            <Link to="/" onClick={toggleMenu}>
              Home
            </Link>
          </li>

          {/* Products Menu for Desktop */}
          <li
            className={`products-menu ${location.pathname.startsWith('/products') ? 'active' : ''}`}
            onMouseLeave={closeProductsMenu} // Close submenu on mouse leave
          >
            <button
              onClick={toggleProductsMenu}
              className="dropdown-toggle"
              aria-haspopup="true"
              aria-expanded={isProductsMenuOpen}
            >
              Products
            </button>
            {isProductsMenuOpen && (
              <ul className="submenu">
                <li>
                  <Link
                    to="/products#whitematter"
                    onClick={closeProductsMenu} // Only close the products menu
                  >
                    WhiteMatter
                  </Link>
                </li>
                {/* Add more product links here */}
              </ul>
            )}
          </li>

          <li className={location.pathname === '/pricing' ? 'active' : ''}>
            <Link to="/pricing" onClick={toggleMenu}>
              Pricing
            </Link>
          </li>

          <li className={location.pathname === '/contact' ? 'active' : ''}>
            <Link to="/contact" onClick={toggleMenu}>
              Contact Us
            </Link>
          </li>

          <li className={location.pathname === '/download' ? 'active' : ''}>
            <Link to="/download" onClick={toggleMenu}>
              Download
            </Link>
          </li>

          {/* Account Link for Logged-In Users (Desktop Only) */}
          {isLoggedIn && (
            <li className={`desktop-auth-link ${location.pathname === '/account' ? 'active' : ''}`}>
              <Link to="/account" onClick={toggleMenu}>
                Account
              </Link>
            </li>
          )}

          {/* Authentication Links in Mobile Menu */}
          {isMenuOpen && (
            <>
              {isLoggedIn ? (
                <>
                  <li className="mobile-auth-link">
                    <Link to="/account" onClick={toggleMenu}>
                      Account
                    </Link>
                  </li>
                  <li className="mobile-auth-link">
                    <button
                      className="logout-button"
                      onClick={handleLogout}
                      aria-label="Logout"
                    >
                      <FaSignOutAlt className="icon" />
                      Logout
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li className="mobile-auth-link">
                    <Link to="/signin" onClick={toggleMenu}>
                      Sign In
                    </Link>
                  </li>
                  <li className="mobile-auth-link">
                    <Link to="/signup" onClick={toggleMenu}>
                      Sign Up
                    </Link>
                  </li>
                </>
              )}
            </>
          )}
        </ul>
      </nav>

      {/* "Actions" Area on the Right */}
      <div className="actions">
        <Link to="/pricing" className="try-button">
          Try for free
        </Link>

        {/* Desktop Auth Buttons */}
        <div className="desktop-auth-buttons">
          {isLoggedIn ? (
            <button className="logout-button" onClick={handleLogout} aria-label="Logout">
              <FaSignOutAlt className="icon" />
              Logout
            </button>
          ) : (
            <button className="login-button" onClick={() => navigate('/signin')} aria-label="Login">
              <FaSignInAlt className="icon" />
              Login
            </button>
          )}
        </div>
      </div>

      {/* Overlay for Mobile Menu Background Fade */}
      {isMenuOpen && <div className="menu-overlay" onClick={toggleMenu} aria-hidden="true"></div>}
    </header>
  );
};

export default Header;
