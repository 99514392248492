import React, { useState, useEffect } from 'react';
import './PricingPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faGift, faFire } from '@fortawesome/free-solid-svg-icons';
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import { loadStripe } from '@stripe/stripe-js';

const PricingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [SKU_ID, setSKU_ID] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [isStripeReady, setIsStripeReady] = useState(false);
  const navigate = useNavigate();

  // Fetch Stripe publishable key on component mount
  useEffect(() => {
    const fetchStripeKey = async () => {
      try {
        const response = await fetch('/config/stripe');
        if (!response.ok) throw new Error('Failed to fetch Stripe publishable key');
        const { publishableKey } = await response.json();
        if (!publishableKey) throw new Error('Publishable key is null or undefined');

        const stripe = await loadStripe(publishableKey);
        setStripePromise(stripe);
        setIsStripeReady(true);
      } catch (error) {
        console.error('Error fetching Stripe publishable key:', error);
      }
    };
    fetchStripeKey();
  }, []);

  // Utility function to verify if the user is logged in by calling a protected endpoint
  // If not 200, redirect to sign-in
  const checkAuthenticated = async () => {
    try {
      const resp = await fetch('/api/profile', {
        method: 'GET',
        credentials: 'include', // Send cookies
      });
      return resp.ok; // true if 200, false if 401, etc.
    } catch (err) {
      console.error('Error checking authentication:', err);
      return false;
    }
  };

  const handleOpenModal = async (plan) => {
    const authenticated = await checkAuthenticated();
    if (!authenticated) {
      // If user is not authenticated, navigate to Sign In with optional state
      navigate('/signin', { state: { target: '/pricing', plan } });
      return;
    }
    // If authenticated, open the OS selection modal
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const handleOSClick = async (os) => {
    let skuId = '';
    if (selectedPlan === 'Free Trial') {
      skuId = os === 'Windows' ? 'Free_Win01' : 'Free_Mac01';
    } else if (selectedPlan === 'Standard') {
      skuId = os === 'Windows' ? 'STD_Win01' : 'STD_Mac01';
    }

    setSKU_ID(skuId);
    setIsModalOpen(false);

    if (selectedPlan === 'Free Trial') {
      const success = await updateSubscription(skuId);
      if (success) {
        setIsSuccessModalOpen(true);
      }
    } else {
      await redirectToCheckout(skuId);
    }
  };

  const updateSubscription = async (skuId) => {
    try {
      const response = await fetch('/api/update-subscription', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include', // Send cookies
        body: JSON.stringify({ skuId }),
      });

      if (!response.ok) {
        const data = await response.json();
        if (data.message === 'Cannot downgrade from a standard plan to a free plan.') {
          setErrorMessage('Cannot downgrade from a standard plan to a free plan.');
        } else {
          setErrorMessage('You already have an active free trial subscription.');
        }
        setIsErrorModalOpen(true);
        return false;
      } else {
        return true; // Success
      }
    } catch (error) {
      console.error('Error updating subscription:', error);
      setErrorMessage('An error occurred while updating your subscription.');
      setIsErrorModalOpen(true);
      return false;
    }
  };

  const redirectToCheckout = async (skuId) => {
    try {
      if (!stripePromise) {
        console.error('Stripe is not initialized yet.');
        return;
      }

      const stripe = await stripePromise;
      if (!stripe) {
        console.error('Stripe instance is null. Initialization failed.');
        return;
      }

      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include', // Send cookies
        body: JSON.stringify({ skuId }),
      });

      if (!response.ok) {
        const { message } = await response.json();
        console.error('Error creating checkout session:', message);
        return;
      }

      const session = await response.json();
      await stripe.redirectToCheckout({ sessionId: session.id });
    } catch (error) {
      console.error('Error redirecting to checkout:', error);
    }
  };

  return (
    <div className="pricing-page">
      <header className="pricing-header tech-bg">
        <h1>Whitematter Pricing Plans</h1>
        <p>Get started with WhiteMatter</p>
      </header>

      <div className="pricing-container">
        <div className="pricing-card trial">
          <h2>Free Trial</h2>
          <p className="price">
            $0<span>/month</span>
          </p>
          <ul className="features">
            <li>First 2 months free</li>
            <li>$5.99 after trial expires</li>
            <li>Best for individual users</li>
            <li>Email support</li>
            <li>Limited AI insights</li>
          </ul>
          <button className="btn-pricing" onClick={() => handleOpenModal('Free Trial')}>
            Start Free Trial
          </button>
        </div>

        <div className="pricing-card basic">
          <h2>Standard</h2>
          <div className="popular">
            <FontAwesomeIcon icon={faFire} className="fire-icon" /> Popular
          </div>
          <p className="price">
            $54<span>/year</span>
          </p>
          <ul className="features">
            <li>First 3 months free</li>
            <li>Best for individual users</li>
            <li>Email support</li>
            <li>AI-driven neurofeedback</li>
          </ul>
          <button className="btn-pricing" onClick={() => handleOpenModal('Standard')}>
            Get Started
          </button>
        </div>

        <div className="pricing-card essential">
          <h2>Enterprise</h2>
          <p className="price">Contact Us</p>
          <ul className="features">
            <li>Employer dashboards</li>
            <li>Best for organizations</li>
            <li>Priority support</li>
            <li>Advanced AI insights</li>
            <li>Manage team productivity and workload</li>
          </ul>
          <button className="btn-pricing" onClick={() => navigate('/contact')}>
            Contact Sales
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-pricing">
          <div className="modal-content-pricing">
            <h3>Choose your OS for the {selectedPlan} plan</h3>
            <div className="os-options-grid">
              <div className="os-option" onClick={() => handleOSClick('MacOS')}>
                <FontAwesomeIcon icon={faApple} className="os-icon" />
                <p>MacOS</p>
              </div>
              <div className="os-option" onClick={() => handleOSClick('Windows')}>
                <FontAwesomeIcon icon={faWindows} className="os-icon" />
                <p>Windows</p>
              </div>
            </div>
            <button className="close-btn-pricing" onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </div>
      )}

      {isSuccessModalOpen && (
        <div className="modal-success">
          <div className="success-modal">
            <h3>Success!</h3>
            <p>Check your email for a registration code.</p>
            <button className="btn" onClick={() => setIsSuccessModalOpen(false)}>
              OK
            </button>
          </div>
        </div>
      )}

      {isErrorModalOpen && (
        <div className="modal error-modal">
          <div className="modal-content">
            <h3>Error</h3>
            <p>{errorMessage}</p>
            <button className="btn" onClick={handleCloseErrorModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingPage;
